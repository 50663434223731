/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Image } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Dovozci"} description={"Dovozci"}>
        <Column className="--menu css-1hlc7wc bg--bottom pb--08 pt--08" menu={true} name={"60fbs28g3iw"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/12874/c4277610a53a4e679ffbffd74c449a88_bl=3_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/12874/c4277610a53a4e679ffbffd74c449a88_bl=3_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/12874/c4277610a53a4e679ffbffd74c449a88_bl=3_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/12874/c4277610a53a4e679ffbffd74c449a88_bl=3_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/12874/c4277610a53a4e679ffbffd74c449a88_bl=3_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/12874/c4277610a53a4e679ffbffd74c449a88_bl=3_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/12874/c4277610a53a4e679ffbffd74c449a88_bl=3_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/12874/c4277610a53a4e679ffbffd74c449a88_bl=3_s=3000x_.png);
    }
  
    `}>
          
          <Menu className="--full" fullscreen={true}>
            
            <ColumnWrapper className="menu-logo-box">
              
              <Subtitle className="subtitle-box fs--72 w--400 lh--1" content={"<span style=\"color: rgb(255, 255, 255);\">Naši dodavatelé</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr4 btn-box--cbtn2 btn-box--sbtn2" href={"/"} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">Hlavní stránka</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr4 btn-box--cbtn2 btn-box--sbtn2" href={"/galerie"} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">Galerie</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr4 btn-box--cbtn2 btn-box--sbtn2" href={"/akce"} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">Akce</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr4 btn-box--cbtn2 btn-box--sbtn2" href={"/pracovni-doba"} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">Pracovní doba</span>"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="--left pb--60 pl--0 pr--0 pt--60" name={"apv28g5cbf"} animS={null} border={null}>
          
          <ColumnWrap className="column__flex --left el--1 --full flex--bottom" style={{"maxWidth":1820}} columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="--left" border={null}>
              
              <Subtitle className="subtitle-box fs--48 w--400 lh--1 pl--0" content={"Odkazy na stránky"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr5 btn-box--cbtn1 btn-box--sbtn2 btn-box--shape4 btn-box--cColor1 btn-box--filling5 fs--20" href={"www.gerflor.cz"} content={"<span style=\"color: rgb(0, 0, 0); text-decoration-line: underline;\">Gerflor</span><br>"}>
              </Button>

              <Title className="title-box title-box--style8 title-box--center" content={""}>
              </Title>

              <Button className="btn-box btn-box--hvr5 btn-box--cbtn1 btn-box--sbtn2 btn-box--shape4 btn-box--filling5 fs--20" href={"www.vpodlahy.cz"} content={"<span style=\"color: rgb(0, 0, 0); text-decoration-line: underline;\">V-Podlahy</span>"}>
              </Button>

              <Title className="title-box title-box--style8 title-box--center" content={""}>
              </Title>

              <Button className="btn-box btn-box--hvr5 btn-box--sbtn2 btn-box--shape4 btn-box--filling5 fs--20" href={"www.floorforever.cz"} content={"<span style=\"color: rgb(0, 0, 0); text-decoration-line: underline;\">Podlahy Šesták</span>"}>
              </Button>

              <Image src={"https://cdn.swbpg.com/t/12874/ee5d4e307ec94d60abc6b6809068bdf8_s=660x_.jpg"} svg={false} sizes="100vw" style={{"maxWidth":666}} srcSet={"https://cdn.swbpg.com/t/12874/ee5d4e307ec94d60abc6b6809068bdf8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/12874/ee5d4e307ec94d60abc6b6809068bdf8_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}